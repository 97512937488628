// 用户
export default {
  namespaced: true,
  state: {
    token: '',
    authKey: ''
  },
  mutations: {
    setSession (state: any, value: any) {
      state.token = value.token
      state.authKey = value.auth_key
    },
    delSession (state: any) {
      state.token = ''
      state.authKey = ''
    }
  },
  actions: {
  },
  getters: {
    getSession (state: any) {
      return {
        token: state.token,
        authKey: state.authKey
      }
    },
    login (state: any) {
      return !!state.token
    }
  }
}
