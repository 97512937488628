import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
    meta: {
      title: '保函申请'
      // login: true
    }
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () => import('../views/index.vue'),
    meta: {
      title: '保函申请'
      // login: true
    }
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/pay.vue'),
    meta: {
      title: '支付'
      // login: true
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/views/Error.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
