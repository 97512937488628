import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible'
import vant from 'vant'
import 'vant/lib/index.css'

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).document.title = to.meta.title === undefined ? '保函申请' : to.meta.title
  if (to.meta.login) {
    console.log(store.getters['session/login'])
    if (!store.getters['session/login']) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
      return
    }
  }
  next()
})
createApp(App).use(vant).use(store).use(router).mount('#app')
